import { ForwardedRef, forwardRef } from 'react'

import * as css from './Checkbox.styles'
import { ICheckbox } from './Checkbox.types'
import { requiredLabel } from '../Input/Input.styles'

const CheckboxRef = (
  {
    inputSize = 'lg',
    background = 'grey25',
    checked = false,
    type = 'checkbox',
    foreground,
    styles,
    disabled,
    error,
    hideLabel,
    label,
    name,
    onBlur,
    onChange,
    required,
    labelProps,
    testid
  }: ICheckbox,
  ref: ForwardedRef<HTMLInputElement>
) => (
  <div css={css.rootStyles({ foreground, styles })}>
    <input
      css={css.fieldStyles({ background, inputSize, styles })}
      aria-invalid={error ? 'true' : 'false'}
      aria-labelledby={`${name}-label`}
      disabled={disabled}
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      ref={ref}
      checked={checked}
      data-testid={testid || 'checkbox'}
    />
    {!hideLabel && (
      <label
        css={css.labelStyles({ labelProps, styles })}
        id={`${name}-label`}
        htmlFor={name}
      >
        {label} {required && <span css={requiredLabel} />}
      </label>
    )}
  </div>
)

const Checkbox = forwardRef(CheckboxRef)

Checkbox.displayName = 'Checkbox'

export default Checkbox
