import { Dispatch, SetStateAction } from 'react'

import { NextRouter } from 'next/router'
import toast from 'react-hot-toast'
import { handleSegmentTracking } from 'utils/analytics'

import ory from '@/utils/ory'
// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError<S>(
  router: NextRouter,
  flowType: 'login' | 'registration' | 'settings' | 'recovery' | 'verification',
  resetFlow: Dispatch<SetStateAction<S | undefined>>
) {
  return async err => {
    handleSegmentTracking({
      message: `${flowType} Error`,
      error: err
    })

    // If code is invalid on 'Please enter the 6-digit code' page
    if (err.response?.data.ui?.messages?.some(m => m.id === 4010008)) {
      return toast.error(
        'The code is invalid or has already been used. Please check your email or resend code.'
      )
    }

    switch (err.response?.data.error?.id) {
      case 'session_aal2_required':
        if (err.response?.data.redirect_browser_to) {
          const redirectTo = new URL(err.response?.data.redirect_browser_to)
          if (flowType === 'settings') {
            redirectTo.searchParams.set('return_to', window.location.href)
          }
          // 2FA is enabled and enforced, but user did not perform 2fa yet!
          window.location.href = redirectTo.toString()
          return
        }
        await router.push('/mfa?aal=aal2&return_to=' + window.location.href)
        return
      case 'session_already_available':
        // User is already signed in, let's have them refresh login to prevent recursive loop. This error will only occur on index page when being redirected from hydra via one of our other apps
        try {
          // Get the email from the current session and set it as a query param to be used on the login form
          const { data } = await ory.toSession()
          const email = data.identity?.traits?.email
          await router.push(`/login?refresh=true&email=${email}`)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
          await router.push('/logout')
        }

        return
      case 'session_refresh_required':
        // We need to re-authenticate to perform this action
        window.location.href = err.response?.data.redirect_browser_to
        return
      case 'self_service_flow_return_to_forbidden':
        // The flow expired, let's request a new one.
        toast.error('The return_to address is not allowed.')
        resetFlow(undefined)
        await router.push('/' + flowType)
        return
      case 'self_service_flow_expired':
        // The flow expired, let's request a new one.
        toast.error('Your interaction expired, please fill out the form again.')
        resetFlow(undefined)
        await router.push('/' + flowType)
        return
      case 'security_csrf_violation':
        // A CSRF violation occurred. Best to just refresh the flow!
        toast.error(
          'A security violation was detected, please fill out the form again.'
        )
        resetFlow(undefined)
        await router.push('/' + flowType)
        return
      case 'security_identity_mismatch':
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined)
        await router.push('/' + flowType)
        return
      case 'browser_location_change_required':
        // Ory Kratos asked us to point the user to this URL.
        window.location.href = err.response.data.redirect_browser_to
        return
    }

    switch (err.response?.status) {
      case 410:
        // The flow expired, let's request a new one.
        resetFlow(undefined)
        await router.push('/' + flowType)
        return
      case 400:
        resetFlow(err?.response?.data)
        return
    }

    // We are not able to handle the error? Return it.
    return Promise.reject(err)
  }
}

// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError
