/**
 *
 * @param string
 * @returns string with just first letter capitalized
 */
export const capitalizeFirstLetter = (string: string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1)

/**
 * @returns random string with date stamp to ensure unique
 */
export const setRandomString = () =>
  Math.random().toString(36).substring(2, 15) +
  Date.now().toString() +
  Math.random().toString(36).substring(2, 15)

export const clamp = (val: number, min: number, max: number) =>
  val > max ? max : val < min ? min : val

/**
 * @param array
 * @returns new array with incremented last item
 */
export const addIncrementedNumberToArray = (array: number[]) => {
  const lastItem = array.length > 0 ? array[array.length - 1] : 0
  return [...array, lastItem + 1]
}

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timerId
  return function (...args) {
    clearTimeout(timerId)
    timerId = setTimeout(() => func.apply(this, args), delay)
  }
}

export const throttle = (
  mainFn: (...args: any[]) => void,
  wait: number,
  backupFn?: () => void
) => {
  let timer = null
  return function (...args: any) {
    if (timer) {
      backupFn?.()
      return
    }
    mainFn(...args)
    timer = setTimeout(() => {
      timer = null
    }, wait)
  }
}

/**
 * Format UPPER_SNAKE_CASE text to regular string, if text is empty return empty string
 *
 * Example:
 *  - ASSOCIATION_INCORPORATED => Association incorporated
 *
 * @param text UPPER_SNAKE_CASE text
 * @returns formated string
 */
export const formatUpperSnakeCaseToRegularString = (text: string): string =>
  text
    ? text
        .split('_')
        .map(v => v.charAt(0) + v.slice(1).toLowerCase())
        .join(' ')
    : ''
